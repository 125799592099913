import { Box, Container, Grid } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { IHeaderNavigation, useNavLinks } from '@/hooks/useNavLinks.hook';

import colors from '@/theme/foundations/colors/index';

import { trackEvent } from '@/utils/analytics';

const Navigation = () => {
  const { navLinks } = useNavLinks();

  const handleClick = (item: IHeaderNavigation) => () => {
    trackEvent({
      button_text: item?.label,
      location: 'Streaks Navigation',
      action: `User clicks the '${item?.label}' button`,
      event: 'button_clicked',
    });

    item.onClick?.();
  };

  return (
    <Container
      backgroundColor={colors.navigation.background}
      maxW='auto'
      px='0'
      w='100%'
      zIndex='10'
    >
      <Grid autoFlow='column' h='48px' margin='0 auto' maxW='800px'>
        {navLinks.map((item, idx) => (
          <Box
            alignItems='center'
            as={Link}
            borderBottom='3px solid'
            borderColor={
              item.selected
                ? colors.navigation.borderActive
                : colors.navigation.border
            }
            color={colors.navigation.text}
            display='flex'
            fontSize='16px'
            fontWeight='600'
            justifyContent='center'
            key={idx}
            onClick={handleClick(item)}
            opacity={item.selected ? 1 : 0.7}
            to={item.linkData?.to}
          >
            {item.label}
          </Box>
        ))}
      </Grid>
    </Container>
  );
};

export default Navigation;
