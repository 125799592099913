import React from 'react';

import { Box, Spinner } from '@chakra-ui/react';

import colors from '@/theme/foundations/colors/index';

export const PageLoader: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        h: '100vh',
        w: '100%',
        background: '#000000',
        opacity: '0.7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1999,
      }}
    >
      <Spinner color={colors.Chalk} size='xl' speed='0.65s' thickness='4px' />
    </Box>
  );
};
