import { FC, MutableRefObject } from 'react';

import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { trackEvent } from '@/utils/analytics';
import { GAMEZONE_URL } from '@/utils/constants';

export const RegisterButton = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    trackEvent({
      button_text: 'Register to Play',
      location: 'Streaks Modal',
      action: "User clicks the 'Register to Play' button",
      event: 'button_clicked',
    });

    window.location.assign(GAMEZONE_URL);
  };

  return (
    <Button onClick={handleClick} size='lg' variant='secondary' w='100%'>
      {t(tKeys.registerToPlay)}
    </Button>
  );
};

export const LoginButton = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    trackEvent({
      button_text: 'Login',
      location: 'Streaks Modal',
      action: "User clicks the 'Login' button",
      event: 'button_clicked',
    });

    window.location.assign(GAMEZONE_URL);
  };

  return (
    <Button onClick={handleClick} size='lg' variant='secondary' w='100%'>
      {t(tKeys.login)}
    </Button>
  );
};

export const NextSlideButton: FC<{ sliderRef: MutableRefObject<any> }> = ({
  sliderRef,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (sliderRef && sliderRef.current) {
      trackEvent({
        button_text: 'Next',
        location: 'Streaks Modal',
        action: "User clicks the 'Next' button",
        event: 'button_clicked',
      });
      sliderRef.current.slickNext();
    }
  };

  return (
    <Button onClick={handleClick} size='lg' variant='secondary' w='100%'>
      {t(tKeys.next)}
    </Button>
  );
};
