import React from 'react';

import { Flex, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { NotificationCheckbox } from '@/components/NotificationCheckbox';

import { RoutesEnum } from '@/enums/routes.enum';

import { useJWT } from '@/hooks/useJWT.hook';
import { useSettings } from '@/hooks/useSettings.hook';

import colors from '@/theme/foundations/colors';

export const Footer: React.FC = () => {
  const { isMobile } = useSettings();
  const { jwt } = useJWT();

  return (
    <Flex
      alignItems='center'
      as='footer'
      background={colors.PrimaryDarkPurple}
      color={colors.white}
      flexShrink='0'
      fontSize='16px'
      gap='40px'
      justifyContent='center'
      py='24px'
      width='100%'
    >
      <ChakraLink as={Link} textDecor='underline' to={RoutesEnum.rules}>
        Game Rules
      </ChakraLink>
      <ChakraLink
        href='https://www.wtatennis.com/privacy-policy'
        target='_blank'
        textDecor='underline'
      >
        Privacy Policy
      </ChakraLink>
      {!isMobile && jwt && (
        <NotificationCheckbox color='#FAFAF5' variant='light' />
      )}
    </Flex>
  );
};
