import { lazy } from 'react';

import { RoutesEnum } from '@/enums/routes.enum';

import { IRoute } from '@/routes/types';

const HomePage = lazy(() => import('@/pages/Home'));
const RedirectCallbackPage = lazy(() => import('@/pages/RedirectCallback'));
const LeaderboardPage = lazy(() => import('@/pages/Leaderboard'));
// const PrizesPage = lazy(() => import('@/pages/Prizes'));
const HistoryPage = lazy(() => import('@/pages/History'));
// const GuidePage = lazy(() => import('@/pages/Guide'));
const RulesPage = lazy(() => import('@/pages/Rules'));

export const appRoutes: IRoute[] = [
  {
    path: RoutesEnum.home,
    element: <HomePage />,
  },
  {
    path: RoutesEnum.redirectCallback,
    element: <RedirectCallbackPage />,
  },
  {
    path: RoutesEnum.leaderboard,
    element: <LeaderboardPage />,
  },
  {
    path: RoutesEnum.history,
    element: <HistoryPage />,
  },
  {
    path: RoutesEnum.rules,
    element: <RulesPage />,
  },
];
