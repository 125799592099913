// import { useMemo } from 'react';

import { matchPath, useLocation } from 'react-router-dom'; // useParams

import { DesktopHeader } from '@/components/Layout/Header/DesktopHeader';
import { MobileHeader } from '@/components/Layout/Header/MobileHeader';

import { RoutesEnum } from '@/enums/routes.enum';

import { useSettings } from '@/hooks/useSettings.hook';

export const Header = () => {
  const { isDesktop } = useSettings();
  const { pathname } = useLocation();

  const withoutBackButton = [
    RoutesEnum.home,
    RoutesEnum.leaderboard,
    RoutesEnum.history,
  ].some((route) => matchPath(route, pathname));

  // Desktop Header
  if (isDesktop) return <DesktopHeader />;

  // Mobile Header
  return <MobileHeader {...{ withoutBackButton }} />;
};
