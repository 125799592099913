import React from 'react';

export const Info: React.FC = () => {
  return (
    <svg
      fill='none'
      height='32'
      viewBox='0 0 32 32'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.6667 22.6562H17.3334V19.9937H14.6667V22.6562ZM14.6667 17.3312H17.3334V9.34378H14.6667V17.3312ZM16.0001 29.3124C8.64008 29.3124 2.66675 23.3484 2.66675 16C2.66675 8.65154 8.64008 2.68758 16.0001 2.68758C23.3601 2.68758 29.3334 8.65154 29.3334 16C29.3334 23.3484 23.3601 29.3124 16.0001 29.3124ZM16.0001 5.35006C10.1201 5.35006 5.33341 10.1292 5.33341 16C5.33341 21.8707 10.1201 26.6499 16.0001 26.6499C21.8801 26.6499 26.6667 21.8707 26.6667 16C26.6667 10.1292 21.8801 5.35006 16.0001 5.35006Z'
        fill='currentColor'
      />
    </svg>
  );
};
