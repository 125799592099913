import React, { SVGProps } from 'react';

import colors from '@/theme/foundations/colors/index';

export const StadiumIcon: React.FC<SVGProps<any>> = ({
  fill = colors.Chalk,
  fontSize = '32',
}) => {
  return (
    <svg
      fill='none'
      height={fontSize}
      viewBox='0 0 32 32'
      width={fontSize}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 14.5454C2 12.0827 8.26818 9.45453 16 9.45453C23.7318 9.45453 30 12.0827 30 14.5454L28.3264 22.7163C28.3264 24.88 22.8091 26.6363 16 26.6363C9.19091 26.6363 3.67363 24.88 3.67363 22.7163L2 14.5454Z'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M30 14.5455C30 17.0082 23.7318 19 16 19C8.26818 19 2 17.0082 2 14.5455'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M21.7906 17.0909C20.3016 16.3018 18.2525 15.8182 15.9997 15.8182C13.747 15.8182 11.7616 16.2891 10.2725 17.0591'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M5.42969 15.8182C7.1606 13.9473 11.2397 12.6364 15.9997 12.6364C20.7597 12.6364 24.8388 13.9473 26.5697 15.8182'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M18.545 26.6364V22.8182H13.4541V26.6364'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M16 9.45456V7.54547'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M19.182 5H12.8184V7.54545H19.182V5Z'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M8.36328 10.3646L8.99964 7.54547H10.2724'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M3.42578 12.4582L4.54578 8.81818H5.81851'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M23.6366 10.3646L23.0003 7.54547H21.7275'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M28.5744 12.4584L27.4544 8.81836H26.1816'
        stroke={fill}
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
    </svg>
  );
};
