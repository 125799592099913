import { FC, ReactNode, useRef, useState } from 'react';

import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { tKeys } from '@/i18n';

import keepAlive from '@/assets/how-to-play/keep-alive.jpg';
import welcomeToStreaks from '@/assets/how-to-play/welcome-to-wta-streaks.png';
import winPrize from '@/assets/how-to-play/win-prize.jpg';

import {
  LoginButton,
  NextSlideButton,
  RegisterButton,
} from '@/components/Onboarding/Buttons';

import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useJWT } from '@/hooks/useJWT.hook';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useSettings } from '@/hooks/useSettings.hook';

import { IDataProps } from '@/types/domain';

import { trackEvent } from '@/utils/analytics';
import { onImageError } from '@/utils/onImageError';

import { Dots } from './Onboarding.styles';

type Props = {
  onClose: () => void;
};

export const Onboarding: FC<Props> = (props) => {
  const { onClose } = props;

  const { jwt } = useJWT();
  const { isDesktop } = useSettings();
  const { setItem } = useLocalStorage<string>(StorageKeysEnum.onboarding);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const slides: IDataProps[] = [
    {
      title: 'Welcome to WTA Streaks!',
      text: 'Ready to start your streak? Challenge yourself by acing 25 consecutive questions correctly for your chance to win a $50,000 prize!',
      image: welcomeToStreaks,
    },
    {
      title: 'Keep the Streak Alive',
      text: 'If you miss a day or give an incorrect answer, your streak resets to zero. Boost your chances by utilizing WTA Unlocked and stay updated with the latest matches and scores!',
      image: keepAlive,
    },
    {
      title: 'Win $50,000 on WTA Streaks!',
      text: [
        'Play WTA Streaks daily for your chance to win $50,000! Each day brings you closer to the grand prize by securing 25 consecutive correct answers. Start your streak today.',
        '*The contest is only eligible in USA, UK and Canada',
      ],
      image: winPrize,
    },
  ];

  const isLastSlide = currentIndex === slides.length - 1;

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    edgeFriction: 1,
    speed: 500,
    cssEase: 'linear',
    afterChange: (index: number) => setCurrentIndex(index),
    appendDots: (dots: ReactNode) => <Dots>{dots}</Dots>,
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlePlayClick = () => {
    if (isLastSlide) {
      trackEvent({
        button_text: 'Lets Go',
        location: 'Game Details',
        action: "User clicks the 'Lets Go' button",
        event: 'button_clicked',
      });
    } else {
      trackEvent({
        button_text: 'Skip',
        location: 'Streaks Modal',
        action: "User clicks the 'Skip' button",
        event: 'button_clicked',
      });
    }

    navigate(RoutesEnum.home);
    setItem('true');
    onClose();
  };

  return (
    <Box>
      <Slider {...settings} ref={sliderRef}>
        {slides.map((item: IDataProps, id: number) => {
          return (
            <Box color='black' key={id} px='4px' textAlign='center'>
              <Image
                alt={`slide-${id}`}
                borderRadius='8px'
                height={isDesktop ? '262px' : undefined}
                m='0 auto 20px'
                onError={onImageError}
                src={item.image}
                width={isDesktop ? '313px' : undefined}
              />
              <Text color='#111' fontSize={['18px']} fontWeight='700' mb='20px'>
                {item.title}
              </Text>
              {Array.isArray(item.text) ? (
                item.text.map((text, idx) => (
                  <Text
                    fontSize={['14px']}
                    fontWeight={400}
                    key={idx}
                    m='0 auto'
                    mb='16px'
                  >
                    {text}
                  </Text>
                ))
              ) : (
                <Text fontSize={['14px']} fontWeight={400} m='0 auto'>
                  {item.text}
                </Text>
              )}
            </Box>
          );
        })}
      </Slider>

      <Flex gap='20px' justifyContent='center' marginTop='60px'>
        {isLastSlide && !jwt && (
          <>
            <RegisterButton />
            <LoginButton />
          </>
        )}
        {!isLastSlide && (
          <>
            <NextSlideButton sliderRef={sliderRef} />
            <Button
              onClick={handlePlayClick}
              size='lg'
              variant='secondary'
              w='100%'
            >
              {t(tKeys.skip)}
            </Button>
          </>
        )}
        {isLastSlide && jwt && (
          <Button
            onClick={handlePlayClick}
            size='lg'
            variant='secondary'
            w='100%'
          >
            {t(tKeys.gotIt)}
          </Button>
        )}
      </Flex>
    </Box>
  );
};
