import styled from 'styled-components';

export const Text = styled('p')<{ isDesktop: boolean }>(
  ({ isDesktop, theme }) => ({
    marginBlock: theme.space(2),
    textAlign: 'center',
    fontWeight: 'normal',
    ...(isDesktop && {
      color: theme.colors.black,
      margin: `0 ${theme.space(2)}`,
      flex: 1,
      padding: '10px 0',
    }),
  }),
);

export const Dots = styled('ul')({
  li: {
    background: 'black',
    opacity: '0.5',
    transition: 'width 0.3s ease-in-out',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    button: {
      opacity: '0',
    },
  },
  'li.slick-active': {
    width: '30px',
    height: '8px',
    opacity: '1',
  },
});
