import React, { useEffect, useMemo } from 'react';

import { Container, Flex } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';

import { AdBanner } from '@/components/AdBanner/index';
import { Footer } from '@/components/Layout/Footer';
import { Header } from '@/components/Layout/Header';
import Navigation from '@/components/Navigation';
import { SidebarNavbar } from '@/components/Navigation/SidebarNavigation';
import { Sidebar } from '@/components/Sidebar';

import { RoutesEnum } from '@/enums/routes.enum';

import { useSettings } from '@/hooks/useSettings.hook';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

const Layout: React.FC = () => {
  const { pathname } = useLocation();
  const { isDesktop } = useSettings();

  const isGuidePage = pathname === RoutesEnum.guide;

  const isHeaderShown = useMemo(() => {
    if (IS_HEADER_HIDDEN) return false;
    if (isGuidePage && !isDesktop) return false;
    return true;
  }, [isGuidePage, isDesktop]);

  const isNavShown = !isGuidePage;

  useEffect(
    () => window.parent.postMessage({ type: 'scrollTop' }, '*'),
    [pathname],
  );

  return (
    <Flex direction='column' minH='100vh'>
      <SidebarNavbar />
      {isHeaderShown && <Header />}
      <Sidebar />

      {isNavShown && <Navigation />}

      <AdBanner />

      <Container
        as='main'
        backdropFilter='blur(5px)'
        background={{ base: 'transparent', md: 'rgba(255, 255, 255, 0.30)' }}
        borderRadius='8px'
        boxShadow={{ base: 'none', md: '0px 4px 12px 0px rgba(0, 0, 0, 0.16)' }}
        color='PrimaryDarkPurple'
        flexGrow='1'
        height='auto'
        maxW='630px'
        mb='16px'
        padding={{ base: '16px', md: '24px 40px' }}
        w='100%'
      >
        <Outlet />
      </Container>

      <Footer />
    </Flex>
  );
};

export default Layout;
