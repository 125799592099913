import { DrawerFooter, Flex, Icon, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { ReactComponent as ProfileIcon } from '@/assets/icons/user-profile.svg';

import { NotificationCheckbox } from '@/components/NotificationCheckbox';

import { useJWT } from '@/hooks/useJWT.hook';

import { selectUserInfo } from '@/store/slices/user.slice';

import colors from '@/theme/foundations/colors';

export const SidebarBottom = () => {
  const { username } = useSelector(selectUserInfo);
  const { jwt } = useJWT();

  return (
    <DrawerFooter flexDirection='column' gap='19px' mt='23px'>
      {username && (
        <Flex align='center' color={colors.PrimaryDarkPurple} gap='8px'>
          <Icon as={ProfileIcon} h='32px' w='32px' />
          <Text fontSize='16px' fontWeight='700'>
            {username}
          </Text>
        </Flex>
      )}
      {jwt && <NotificationCheckbox color={colors.PrimaryDarkPurple} />}
    </DrawerFooter>
  );
};
