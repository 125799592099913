import { extendTheme } from '@chakra-ui/react';

import breakpoints from '@/theme/foundations/breakpoints';
import colors from '@/theme/foundations/colors';
import components from '@/theme/foundations/components';
import fonts from '@/theme/foundations/fonts';
import radius from '@/theme/foundations/radius';
import shadows from '@/theme/foundations/shadows';
import space from '@/theme/foundations/space';

const theme = extendTheme({
  fonts,
  colors,
  breakpoints,
  space,
  shadows,
  radii: radius,
  components,
});

export default theme;
