import React from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link as ChakraLink,
  Link,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { SidebarBottom } from '@/components/Sidebar/SidebarBottom';

import { Logo } from '@/elements/Logo/index';

import { useNavLinks } from '@/hooks/useNavLinks.hook';
import { useSettings } from '@/hooks/useSettings.hook';

export const Sidebar: React.FC = () => {
  const { isSidebarOpen, onSidebarClose } = useSettings();
  const theme = useTheme();
  const { sidebarNavLinks } = useNavLinks();

  return (
    <Drawer isOpen={isSidebarOpen} onClose={onSidebarClose} placement='left'>
      <DrawerOverlay backdropFilter='blur(8px)' />
      <DrawerContent backgroundColor={theme.colors.sidebar.bg} maxW='250px'>
        <DrawerHeader pt='50px'>
          <Link href='https://www.wtatennis.com/unlocked'>
            <Logo isSidebar={true} />
          </Link>
        </DrawerHeader>

        <DrawerBody
          display='flex'
          flexDirection='column'
          gap='4px'
          mt='64px'
          p='0'
        >
          {sidebarNavLinks.map((item, idx) => (
            <ChakraLink
              _hover={{ textDecoration: 'none' }}
              as={ReactRouterLink}
              key={idx}
              onClick={() => onSidebarClose()}
              to={item.linkData.to}
            >
              <Flex
                _hover={{ color: theme.colors.sidebar.text }}
                align='center'
                bg={item.selected ? theme.colors.sidebar.text : 'inherit'}
                color={
                  item.selected ? theme.colors.Chalk : theme.colors.sidebar.text
                }
                gap='24px'
                p='16px'
                position='relative'
                w='100%'
              >
                {item.renderIcon({
                  fill: item.selected
                    ? theme.colors.Chalk
                    : theme.colors.sidebar.text,
                })}
                <Text
                  fontSize='16px'
                  fontWeight='500'
                  lineHeight='1.4'
                  textTransform='capitalize'
                >
                  {item.label}
                </Text>
              </Flex>
            </ChakraLink>
          ))}
        </DrawerBody>

        <SidebarBottom />
      </DrawerContent>
    </Drawer>
  );
};
