const PrimaryDarkPurple = '#2D0046'; //rgba(45,0,70,1)
const PrimaryDarkPurple40 = 'rgba(45,0,70,0.4)';
const PrimaryDarkPurple80 = 'rgba(45,0,70,0.8)';
const PrimaryElectricYellow = '#F5FA00';
const Gradient01 = 'linear-gradient(180deg, #7814FF 0%, #2D0046 100%)';
const SecondaryGrass =
  'linear-gradient(132deg, #00F0CB 0%, #00CD5A 50%, #009B5A 100%)';
const Chalk = '#FAFAF5';

const colors = {
  absoluteDark: '#000000',
  defaultGray: '#666666',
  appTextColor: Chalk,
  white: Chalk,
  Chalk,
  PrimaryDarkPurple,
  PrimaryDarkPurple80,
  PrimaryElectricYellow,
  Gradient01,
  SecondaryGrass,
  PoweredGreen: SecondaryGrass,

  toast: {
    success: '#009656',
    error: '#E22F2F',
    text: Chalk,
  },

  appBg: {
    primaryBg: '#292935',
    secondaryBg: Chalk,
  },
  header: {
    border: 'rgba(255,255,255, 0.3)',
    logo: Chalk,
    text: Chalk,
    primaryBg: '#292935',
    secondaryBg: '#A01313',
  },
  navbar: {
    bg: '#292935',
    text: Chalk,
    selected: '#F73535',
  },
  contestCard: {
    bg: Chalk,
    text: '#E22F2F',
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
  },
  cardSmall: {
    bg: '#292935',
    descriptionBg: '#F73535',
    text: Chalk,
    buttonBg: Chalk,
    buttonBgActive: Chalk,
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
    title: Chalk,
    description: Chalk,
  },
  tile: {
    bg: 'linear-gradient(251deg, #1CFF47 -12.7%, #25BD95 103.67%)',
    text: '#111111',
  },
  contestTile: {
    bg: '#33CFFF',
    text: '#111111',
    liveText: Chalk,
    liveBg: 'linear-gradient(99deg, #F73535 13.3%, #F2B60F 86.79%)',
  },
  userContestTile: {
    bg: '#3B3B54',
    text: Chalk,
    winBg: 'linear-gradient(251deg, #1CFF47 -12.7%, #25BD95 103.67%)',
    winText: '#292935',
  },
  timer: {
    bg: '#FAFAF5',
    text: '#19191C',
    image: '#666666',
    imageActive: '#E22F2F',
  },
  section: {
    title: '#FAFAF5',
    leftImage: {
      title: '#E22F2F',
      subtitle: '#E22F2F',
      description: '#E22F2F',
    },
  },
  carousel: {
    bullet: '#FAFAF5',
    bulletActive: '#FAFAF5',
  },
  langSwitcher: {
    bg: '#292935',
    icon: '#F73535',
    border: '#A01313',
    text: '#FAFAF5',
  },
  input: {
    bg: '#3B3B54',
    border: '#88B3FF',
    text: '#FAFAF5',
    placeholder: '#8A8AB2',
    invalid: '#E22F2F',
    cancel: '#D9D9D9',
    hint: '#D9D9D9',
    success: '#009656',
    eye: '#D9D9D9',
  },
  checkbox: {
    label: '#FAFAF5',
    bg: '#3B3B54',
    icon: '#08F9AE',
  },
  signup: {
    title: '#FAFAF5',
    text: '#FAFAF5',
  },
  login: {
    title: '#FAFAF5',
    text: '#FAFAF5',
  },
  profile: {
    title: '#FAFAF5',
    text: '#FAFAF5',
  },
  resetPassword: {
    title: '#FAFAF5',
    text: '#FAFAF5',
  },
  forgotPassword: {
    title: '#FAFAF5',
    text: '#FAFAF5',
    success: '#009656',
  },
  unsubscribe: {
    title: '#FAFAF5',
    text: '#FAFAF5',
    iconButton: '#FAFAF5',
  },
  countrySelect: {
    bg: '#3B3B54',
    border: 'transparent',
    text: '#FAFAF5',
    menuBg: '#3B3B54',
    placeholder: '#8A8AB2',
    arrow: '#8A8AB2',
  },
  sidebar: {
    bg: Chalk,
    close: '#000',
    logo: PrimaryDarkPurple,
    text: PrimaryDarkPurple,
    overlay: 'rgba(0, 0, 0, 0.40)',
  },
  button: {
    defaultText: '#FAFAF5',
    primary: {
      bg: PrimaryDarkPurple,
      disabledBg: 'linear-gradient(180deg, #7BD46D 0%, #529248 100%)',
      text: '#FAFAF5',
      disabledText: 'rgba(7, 60, 28, 0.3)',
      activeBg: PrimaryDarkPurple,
    },
    secondary: {
      bg: '#EAE5EC',
      border: '#FAFAF5',
      disabledBg: '#6A6A6A',
      text: '#FAFAF5',
      activeBg: '#EAE5EC',
      activeText: PrimaryDarkPurple,
    },
  },
  navigation: {
    border: 'transparent',
    borderActive: PrimaryElectricYellow,
    text: Chalk,
    background: PrimaryDarkPurple,
  },
  desktopSideNavbar: {
    bg: '#292935',
    text: '#FAFAF5',
    textActive: '#F73535',
  },
  stepper: {
    background: '#19191C',
    activeColor: Chalk,
    activeBgColor: PrimaryDarkPurple,
    correct: SecondaryGrass,
    incorrect: '#F73535',
    color: Chalk,
    backgroundColor: PrimaryDarkPurple40,
    grey: 'rgba(241, 243, 245, 0.60)',
  },
  mobileStepper: {
    mainBg: '#19191C',
    buttonBg: '#2B1D5C',
  },
  home: {
    questionBlockBg: '#173476',
    activeText: '#33CFFF',
    buttonText: Chalk,
  },
  prizes: {
    rulesBg: PrimaryDarkPurple,
    prize: '#08F9AE',
  },

  playQuestion: {
    hintPrizeText: '#33CFFF',
    winPrizeText: '#08F9AE',
    bg: PrimaryDarkPurple,
    eliminatedText: '#F73535',
  },

  playQuestionAnswered: {
    title: PrimaryDarkPurple,
    text: '#FAFAF5',
    prize: '#08F9AE',
    prizeBg: '#173476',
    optionBg: '#88B3FF',
    optionText: '#19191C',
  },

  pickSaved: {
    hintNoPrize: '#33CFFF',
  },

  history: {
    text: PrimaryDarkPurple,
    background: '#FAFAF5',
    questionBorder: PrimaryDarkPurple,
    incorrect: '#E9634E',
    correct: SecondaryGrass,
    incorrectBorder: '#DC143C',
    correctBorder: SecondaryGrass,
    correctOptionBackground: SecondaryGrass,
    defaultBorder: PrimaryDarkPurple,
    defaultOptionBackground: PrimaryDarkPurple,
  },

  gradientCasino: 'linear-gradient(99deg, #173476 13.21%, #F73535 86.79%)',
  answerButton: {
    // bg: '#3B3B54',
    bg: Chalk,
    disabledBg: '#5c5c5c',
    activeBg: PrimaryDarkPurple,
    correctBg: SecondaryGrass,
    incorrectBg: '#DC143C',
    dark_text: PrimaryDarkPurple,
    white_text: Chalk,
    disabled_text: '#bfbfbf',
    border_color: PrimaryDarkPurple,
    iconBg: 'rgba(95, 95, 122, 0.50)',
    activeIconBg: 'rgba(97, 146, 216, 0.50)',
  },

  modal: {
    bg: Chalk,
    overlayBg: 'rgba(0, 0, 0, 0.40)',
    title: '#111111',
    text: Chalk,
  },

  table: {
    bg: '#292935',
    nonPairRow: '#292935',
    pairRow: '#1B1B1B',
    currentUserBg: '#316EF7',
    text: Chalk,
  },
};

export default colors;
