import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import '@/i18n/i18n';

import { IframeResizer } from '@/containers/IframeResizer/IframeResizer';
import { setupStore } from '@/store';
import theme from '@/theme';

import App from './App';

import './index.css';
import './fonts/Marsden-font/stylesheet.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const store = setupStore();

root.render(
  <>
    <IframeResizer />
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </>,
);
