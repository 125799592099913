import { RoutesEnum } from '@/enums/routes.enum';

export const getNavigationHelperText = (
  pathname: string,
): string | undefined => {
  if (pathname.includes(RoutesEnum.rules)) {
    return 'Game Rules';
  }

  if (pathname === RoutesEnum.complete) {
    return 'Complete your profile';
  }
};
